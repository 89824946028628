import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Screen42 } from '../../../components/images/support/users/Screen42.generated';
import { Screen43 } from '../../../components/images/support/users/Screen43.generated';
import { Screen44 } from '../../../components/images/support/users/Screen44.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "invite-an-admin-user-"
    }}>{`Invite an Admin User 👩‍💻`}</h1>
    <p>{`This section shows how to invite an admin user to Canyou. `}</p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Navigate to the Users menu and click `}<strong parentName="li">{`Add user`}</strong></li>
    </ol>

    <Screen42 mdxType="Screen42" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Select the `}<strong parentName="li">{`Admin`}</strong>{` user type.`}</li>
    </ol>

    <Screen43 mdxType="Screen43" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Complete the rest of the admins information and click `}<strong parentName="li">{`Add`}</strong>{`.`}</li>
    </ol>
    <p>{`This will send an email to the user inviting them to become an administrator of the respective organisation.`}</p>
    <p>{`When the user accepts the invite they will have full access to control the organisations Canyou account.`}</p>

    <Screen44 mdxType="Screen44" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      